@charset "UTF-8";

/*!
* Bootstrap Reboot v4.2.1 (https://getbootstrap.com/)
* Copyright 2011-2019 The Bootstrap Authors
* Copyright 2011-2019 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
* Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
*/
*,
:after,
:before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: .5rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none
}

address {
    font-style: normal;
    line-height: inherit
}

address,
dl,
ol,
ul {
    margin-bottom: 1rem
}

dl,
ol,
ul {
    margin-top: 0
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: #0056b3;
    text-decoration: underline
}

/* a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
} */

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto
}

figure {
    margin: 0 0 1rem
}

img {
    border-style: none
}

img,
svg {
    vertical-align: middle
}

svg {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

select {
    word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

img {
    max-width: 100%
}

a,
a:hover {
    color: inherit
}

a:hover {
    text-decoration: none
}

::-moz-selection {
    background-color: #36cca5;
    color: #fff
}

::selection {
    background-color: #36cca5;
    color: #fff
}

a::selection {
    background-color: #fff;
    color: #36cca5
}

/*!
* Bootstrap Grid v4.0.0 (https://getbootstrap.com)
* Copyright 2011-2018 The Bootstrap Authors
* Copyright 2011-2018 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/
@-ms-viewport {
    width: device-width
}

html {
    -ms-overflow-style: scrollbar
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none
}

.col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
}

.col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
}

.col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
}

.col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
}

.col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.3333333333%
}

.offset-2 {
    margin-left: 16.6666666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.3333333333%
}

.offset-5 {
    margin-left: 41.6666666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.3333333333%
}

.offset-8 {
    margin-left: 66.6666666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.3333333333%
}

.offset-11 {
    margin-left: 91.6666666667%
}

@media (min-width:576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-sm-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-sm-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-sm-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-sm-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-sm-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        order: -1
    }

    .order-sm-last {
        order: 13
    }

    .order-sm-0 {
        order: 0
    }

    .order-sm-1 {
        order: 1
    }

    .order-sm-2 {
        order: 2
    }

    .order-sm-3 {
        order: 3
    }

    .order-sm-4 {
        order: 4
    }

    .order-sm-5 {
        order: 5
    }

    .order-sm-6 {
        order: 6
    }

    .order-sm-7 {
        order: 7
    }

    .order-sm-8 {
        order: 8
    }

    .order-sm-9 {
        order: 9
    }

    .order-sm-10 {
        order: 10
    }

    .order-sm-11 {
        order: 11
    }

    .order-sm-12 {
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.3333333333%
    }

    .offset-sm-2 {
        margin-left: 16.6666666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.3333333333%
    }

    .offset-sm-5 {
        margin-left: 41.6666666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.3333333333%
    }

    .offset-sm-8 {
        margin-left: 66.6666666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.3333333333%
    }

    .offset-sm-11 {
        margin-left: 91.6666666667%
    }
}

@media (min-width:768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-md-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-md-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        order: -1
    }

    .order-md-last {
        order: 13
    }

    .order-md-0 {
        order: 0
    }

    .order-md-1 {
        order: 1
    }

    .order-md-2 {
        order: 2
    }

    .order-md-3 {
        order: 3
    }

    .order-md-4 {
        order: 4
    }

    .order-md-5 {
        order: 5
    }

    .order-md-6 {
        order: 6
    }

    .order-md-7 {
        order: 7
    }

    .order-md-8 {
        order: 8
    }

    .order-md-9 {
        order: 9
    }

    .order-md-10 {
        order: 10
    }

    .order-md-11 {
        order: 11
    }

    .order-md-12 {
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.3333333333%
    }

    .offset-md-2 {
        margin-left: 16.6666666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.3333333333%
    }

    .offset-md-5 {
        margin-left: 41.6666666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.3333333333%
    }

    .offset-md-8 {
        margin-left: 66.6666666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.3333333333%
    }

    .offset-md-11 {
        margin-left: 91.6666666667%
    }
}

@media (min-width:992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-lg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-lg-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-lg-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-lg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-lg-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        order: -1
    }

    .order-lg-last {
        order: 13
    }

    .order-lg-0 {
        order: 0
    }

    .order-lg-1 {
        order: 1
    }

    .order-lg-2 {
        order: 2
    }

    .order-lg-3 {
        order: 3
    }

    .order-lg-4 {
        order: 4
    }

    .order-lg-5 {
        order: 5
    }

    .order-lg-6 {
        order: 6
    }

    .order-lg-7 {
        order: 7
    }

    .order-lg-8 {
        order: 8
    }

    .order-lg-9 {
        order: 9
    }

    .order-lg-10 {
        order: 10
    }

    .order-lg-11 {
        order: 11
    }

    .order-lg-12 {
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.3333333333%
    }

    .offset-lg-2 {
        margin-left: 16.6666666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.3333333333%
    }

    .offset-lg-5 {
        margin-left: 41.6666666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.3333333333%
    }

    .offset-lg-8 {
        margin-left: 66.6666666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.3333333333%
    }

    .offset-lg-11 {
        margin-left: 91.6666666667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-xl-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-xl-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-xl-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-xl-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-xl-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        order: -1
    }

    .order-xl-last {
        order: 13
    }

    .order-xl-0 {
        order: 0
    }

    .order-xl-1 {
        order: 1
    }

    .order-xl-2 {
        order: 2
    }

    .order-xl-3 {
        order: 3
    }

    .order-xl-4 {
        order: 4
    }

    .order-xl-5 {
        order: 5
    }

    .order-xl-6 {
        order: 6
    }

    .order-xl-7 {
        order: 7
    }

    .order-xl-8 {
        order: 8
    }

    .order-xl-9 {
        order: 9
    }

    .order-xl-10 {
        order: 10
    }

    .order-xl-11 {
        order: 11
    }

    .order-xl-12 {
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.3333333333%
    }

    .offset-xl-2 {
        margin-left: 16.6666666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.3333333333%
    }

    .offset-xl-5 {
        margin-left: 41.6666666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.3333333333%
    }

    .offset-xl-8 {
        margin-left: 66.6666666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.3333333333%
    }

    .offset-xl-11 {
        margin-left: 91.6666666667%
    }
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }

    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

@media (min-width:576px) {
    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }
}

@media (min-width:1200px) {
    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }
}

html {
    font-display: swap
}

@font-face {
    font-family: business-mlz;
    src: url(../images/business-mlz.8a26a20a.eot);
    src: url(../images/business-mlz.8a26a20a.eot?#iefix) format("embedded-opentype"), url(../images/business-mlz.7da307de.woff) format("woff"), url(../images/business-mlz.4d368938.ttf) format("truetype");
    font-weight: 400;
    font-style: normal
}

[data-icon]:before {
    content: attr(data-icon)
}

[class*=" mlz-"]:before,
[class^=mlz-]:before,
[data-icon]:before {
    font-family: business-mlz !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.mlz-angle-right:before {
    content: "r"
}

.mlz-angle-left:before {
    content: "l"
}

.mlz-right-open-big:before {
    content: "a"
}

.mlz-minus:before {
    content: "b"
}

.mlz-bars:before {
    content: "c"
}

.page-heading {
    font-size: 28px
}

@media screen and (min-width:320px) {
    .page-heading {
        font-size: calc(28px + (6200vw - 19840px)/1046)
    }
}

@media screen and (min-width:1366px) {
    .page-heading {
        font-size: 90px
    }
}

.page-sub-heading {
    font-size: 18px
}

@media screen and (min-width:320px) {
    .page-sub-heading {
        font-size: calc(18px + (1400vw - 4480px)/1046)
    }
}

@media screen and (min-width:1366px) {
    .page-sub-heading {
        font-size: 32px
    }
}

.banner-heading {
    font-size: 36px
}

@media screen and (min-width:320px) {
    .banner-heading {
        font-size: calc(36px + (2700vw - 8640px)/1046)
    }
}

@media screen and (min-width:1366px) {
    .banner-heading {
        font-size: 63px
    }
}

.banner-sub-heading {
    font-size: 24px
}

@media screen and (min-width:320px) {
    .banner-sub-heading {
        font-size: calc(24px + (1200vw - 3840px)/1046)
    }
}

@media screen and (min-width:1366px) {
    .banner-sub-heading {
        font-size: 36px
    }
}

.banner-description {
    font-size: 14px
}

@media screen and (min-width:320px) {
    .banner-description {
        font-size: calc(14px + (600vw - 1920px)/1046)
    }
}

@media screen and (min-width:1366px) {
    .banner-description {
        font-size: 20px
    }
}

.btn-mz {
    padding: 5px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all .3s;
    display: inline-block;
    font-family: Comfortaa, cursive;
    font-weight: 700;
    text-align: center;
    border: 2px solid transparent;
    min-width: 120px
}

.btn-mz:first-letter {
    text-transform: uppercase
}

.btn-mz.btn--full {
    width: 100%;
    text-align: center
}

.btn-mz.is--disabled {
    cursor: not-allowed;
    pointer-events: none
}

.btn-mz.is--disabled span {
    opacity: .52
}

.btn-mz:focus {
    outline: none
}

.btn-mz:hover {
    color: #fff;
    text-decoration: none
}

.btn--group .btn-mz--third,
.btn--group .btn-mz:nth-of-type(2) {
    margin-left: 20px
}

.btn--brand-color {
    --color: #36cca5
}

.btn--red-color {
    --color: #F86156;
}

.btn--info-color {
    --color: #62b4f9
}

.btn--expo-color {
    --color: #4D2B85;
}

.btn--customizer-color {
    --color: #fbb5ae;
}

.btn--yellow-color {
    --color: #FFCA65;
}

.btn--product-color {
    --color: #f80
}

.btn--details-color {
    --color: #ff5252
}

.btn--business-color {
    --color: #b604b6
}

.btn--userinput-color {
    --color: #63b4f9
}

.btn--radius {
    border-radius: 31px
}

.btn--outline {
    color: var(--color);
    border-color: var(--color);
    background-color: transparent
}

.btn--filled {
    background-color: var(--color);
    color: #fff
}

.btn-filled {
    background-color: white;
    color: var(--color);
}

.btn--shadow {
    box-shadow: 0 0 6px var(--color);
    transition: all .5s
}

.btn--shadow:hover {
    box-shadow: 0 0 27px 0 var(--color)
}

.btn-shadow {
    box-shadow: 0 0 6px white;
    transition: all .5s
}

.btn-shadow:hover {
    box-shadow: 0 0 27px 0 white;
}

@media screen and (min-width:320px) and (max-width:1023px) {
    .btn--shadow {
        box-shadow: 0 0 27px 0 var(--color)
    }

    .btn--group .btn-mz--third {
        margin-left: 0
    }
}

input[type=email],
input[type=search],
input[type=tel],
input[type=text],
textarea {
    border: 0;
    color: white;
    padding: 5px 20px;
    font-size: 16px;
    resize: none;
    font-family: Comfortaa, cursive;
    background: transparent;
    box-shadow: none;
    border-bottom: 2px solid white;
}

input[type=email]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=text]:focus,
textarea:focus {
    outline: none !important;
    box-shadow: none
}

input[type=email]:-internal-autofill-selected,
input[type=search]:-internal-autofill-selected,
input[type=tel]:-internal-autofill-selected,
input[type=text]:-internal-autofill-selected,
textarea:-internal-autofill-selected {
    color: #36cca5
}

.label-hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden
}

::-moz-placeholder {
    color: white;
    font-size: 16px;
    font-family: Comfortaa, cursive
}

:-ms-input-placeholder {
    color: white;
    font-size: 16px;
    font-family: Comfortaa, cursive
}

::-webkit-input-placeholder {
    color: white;

    font-size: 16px;
    font-family: Comfortaa, cursive
}

.text-justify {
    text-align: justify !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-italic {
    font-style: italic !important
}

.text-white {
    color: #fff !important
}

.text-muted {
    color: #6c757d !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.brand--font-color {
    color: #36cca5 !important
}

.brand--bg-color {
    background-color: #36cca5;
    color: #fff
}

.brand--border-color,
.hover--brand-border:hover {
    border-color: #36cca5
}

.hover--brand-font-color:hover {
    color: #36cca5 !important
}

.hover--brand-bg-color:hover {
    background-color: #36cca5 !important
}

.brand--color {
    color: #36cca5
}

.info--color {
    color: #62b4f9
}

.expo--color {
    color: #4D2B85;
}

.customizer--color {
    color: #fbb5ae;
}


.light--color {
    color: #4EE1BE;
}

.red--color {
    color: #F86156;
}

.yellow--color {
    color: #FFCA65;
}

.product--color {
    color: #f80
}

.need--color {
    color: #94D141
}

.details--color {
    color: #ff5252
}

.business--color {
    color: #b604b6
}

.userinput--color {
    color: #63b4f9
}

.white--color {
    color: #fff
}

.hero-banner {
    height: 110vh;
    position: relative;
    background-image: url(../images/banner.png);
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;

    background-position: bottom;
    background-size: cover
}

.hero-banner svg {
    width: 480px;
    position: absolute
}

.hero-banner .hero-melzo {
    position: relative
}

.hero-banner .hero-awesome path {
    animation: awesome-loader 6s cubic-bezier(.78, .07, .92, .83) reverse;
    animation-delay: 6s
}

.hero-banner .hero-awesome path,
.hero-banner .hero-discover path {
    stroke-dasharray: 4150;
    stroke-dashoffset: 4150;
    stroke: #fff;
    stroke-width: 2
}

.hero-banner .hero-discover path {
    animation: discovery-loader 6s cubic-bezier(.78, .07, .92, .83) reverse
}

.hero-banner .hero-melzo path {
    stroke-dasharray: 820;
    stroke-dashoffset: 820;
    animation: melzo-loader 6s cubic-bezier(.78, .07, .92, .83);
    animation-fill-mode: forwards;
    animation-delay: 12s;
    stroke: #fff;
    stroke-width: 2
}

.hero-banner .banner--details {
    position: absolute;
    left: 50%;
    top: 50vh;
    transform: translate(-50%, -50%)
}

@keyframes discovery-loader {
    0% {
        stroke-dashoffset: 4150
    }

    25% {
        fill: #fff;
        stroke-dashoffset: 3800
    }
}

@keyframes awesome-loader {
    0% {
        stroke-dashoffset: 4150
    }

    25% {
        fill: #fff;
        stroke-dashoffset: 3800
    }
}

@keyframes melzo-loader {
    0% {
        stroke-dashoffset: 820
    }

    25% {
        fill: #fff;
        stroke-dashoffset: 0
    }

    to {
        fill: #fff;
        stroke-dashoffset: 0;
        stroke: transparent
    }
}

@media screen and (min-width:320px) and (max-width:575px) {
    .hero-banner svg {
        width: 250px;
        position: absolute
    }
}





.subscribe-box {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    background-color: #f3f3f3;
    overflow: hidden;
    border-radius: 31px
}

.subscribe-box .subscribe--form-fields {
    flex-basis: auto
}

.subscribe-box input {
    flex: 1 0 60%;
    box-shadow: none;
    max-width: 60%
}

.subscribe-box button {
    right: 0;
    flex: 1 0 140px
}

.subscribe-box .thanks-message {
    margin-top: 10px;
    font-family: Comfortaa, cursive;
    color: #656565;
    padding-left: 20px
}

@media (min-width:320px) and (max-width:1200px) {
    .subscribe-box {
        display: flex
    }

    .subscribe-box input {
        max-width: calc(100% - 140px)
    }

    .subscribe-box button {
        flex: 1 0 140px
    }
}

.first-letter-input {
    position: relative
}

.first-letter-input input,
.first-letter-input textarea {
    background-color: transparent;
    width: 100%;
    padding-left: 0
}

.first-letter-input input~label,
.first-letter-input textarea~label {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 15px;
    font-size: 0;
    height: 0;
    width: 0;
    transition: all .5s;
    margin: 0
}

/* .first-letter-input input~label:before,
.first-letter-input textarea~label:before {
  content: "";
  position: absolute;
  background-color: white;
  opacity: 1;
  left: 0;
  bottom: 0;
  width: 15px;
  height: 1px;
  transition: all .5s
}

.first-letter-input input:focus~label:before,
.first-letter-input input:hover~label:before,
.first-letter-input textarea:focus~label:before,
.first-letter-input textarea:hover~label:before {
  background-color: white;
  width: 150px
} */

/* .testimonial-carousel {
    margin-top: 3rem
} */

.dotblue {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    /* background-color: rgba(88, 181, 252, .33); */
    border-radius: 50%;
    background-color: #62b4f9;
    opacity: 0.33;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dotyellow {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    /* background-color: rgba(255, 202, 101, .33); */
    border-radius: 50%;
    background-color: #FFCA65;
    opacity: 0.33;
    display: inline-block;
    transition: background-color 0.6s ease;
}

footer {

    background-image: url(../images/contactusbg.png);

}

@media(max-width:600px) {
    footer {
        background-image: url(../images/square.png);
    }
}

@media only screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {
    footer {
        background-image: url(../images/square.png);
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    footer {
        background-image: url(../images/square.png);
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    footer {
        background-image: url(../images/square.png);
    }
}

.slick-slider {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list,
.slick-slider {
    position: relative;
    display: block
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: none
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0)
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slick-track:after,
.slick-track:before {
    content: "";
    display: table
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.slick-next,
.slick-prev {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0;
    border: none
}

.slick-next,
.slick-next:focus,
.slick-next:hover,
.slick-prev,
.slick-prev:focus,
.slick-prev:hover {
    background: transparent;
    color: transparent;
    outline: none
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
    opacity: 1
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: .25
}

.slick-next:before,
.slick-prev:before {
    font-family: slick;
    font-size: 20px;
    line-height: 1;
    color: #fff;
    opacity: .75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-prev {
    left: -25px
}

[dir=rtl] .slick-prev {
    left: auto;
    right: -25px
}

.slick-prev:before {
    content: "←"
}

[dir=rtl] .slick-prev:before {
    content: "→"
}

.slick-next {
    right: -25px
}

[dir=rtl] .slick-next {
    left: -25px;
    right: auto
}

.slick-next:before {
    content: "→"
}

[dir=rtl] .slick-next:before {
    content: "←"
}

.slick-dotted.slick-slider {
    margin-bottom: 30px
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%
}

.slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0
}

.slick-dots li,
.slick-dots li button {
    height: 20px;
    width: 20px;
    cursor: pointer
}

.slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    outline: none;
    line-height: 0;
    font-size: 0;
    color: transparent;
    padding: 5px
}

.slick-dots li button:focus,
.slick-dots li button:hover {
    outline: none
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 1
}

.slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "•";
    width: 20px;
    height: 20px;
    font-family: slick;
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    color: #000;
    opacity: .25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-dots li.slick-active button:before {
    color: #000;
    opacity: .75
}

.slick-slide {
    outline: none
}

.slick-next,
.slick-prev {
    width: auto;
    height: auto
}

.slick-prev {
    left: -35px
}

.slick-next {
    right: -35px
}

.slick-arrow:before {
    font-family: business-mlz;
    font-size: 44px
}

.slick-next:before {
    content: "r"
}

.slick-prev:before {
    content: "l"
}

.slick-dots {
    bottom: -45px
}

.slick-dots li,
.slick-dots li button {
    width: auto;
    height: auto
}

.slick-dots li button {
    padding: 0
}

.slick-dots li button:before {
    position: relative;
    width: auto;
    height: auto;
    font-size: 55px;
    color: #fff
}

.slick-dots li.slick-active button:before {
    color: #fff
}

.our-clients .slick-list {
    padding: 10px 0
}

.our-clients .slick-next,
.our-clients .slick-prev {
    opacity: 0;
    visibility: hidden;
    transition: all .5s
}

.our-clients .slick-dots li.slick-active button:before,
.our-clients .slick-dots li button:before,
.our-clients .slick-next:before,
.our-clients .slick-prev:before {
    color: #000
}

.contact-box .contact-box--field {
    display: flex;
    flex-wrap: wrap
}

.contact-box .contact-box--field.two--coloumn .field-container {
    max-width: 50%;
    flex: 0 0 50%
}

.contact-box .contact-box--field .field-container {
    margin-bottom: 70px;
    padding-right: 30px
}


@media(max-width:600px) {
    .contact-box .contact-box--field .field-container {
        margin-bottom: 30px;
        padding-right: 30px
    }
}

.contact-box .contact-box--field .field-container.single-field {
    max-width: 100%;
    flex: 0 0 100%
}

.contact-box .contact-box--field input,
.contact-box .contact-box--field textarea {
    color: white;
}

.contact-box .contact-box--field input ::-moz-placeholder,
.contact-box .contact-box--field textarea ::-moz-placeholder {
    color: white;
}

.contact-box .contact-box--field input :-ms-input-placeholder,
.contact-box .contact-box--field textarea :-ms-input-placeholder {
    color: #707070
}

.contact-box .contact-box--field input ::-webkit-input-placeholder,
.contact-box .contact-box--field textarea ::-webkit-input-placeholder {
    color: #707070
}

@media (min-width:320px) and (max-width:575px) {
    .contact-box .contact-box--field.two--coloumn .field-container {
        max-width: 100%;
        flex: 0 0 100%
    }
}

.mr-auto {
    margin-left: auto;
    margin-right: auto
}

.pd-0 {
    padding: 0
}

.pd-t-0 {
    padding-top: 0
}

.pd-b-0 {
    padding-bottom: 0
}

.pd-l-0 {
    padding-left: 0
}

.pd-r-0 {
    padding-right: 0
}

.pd-tb-0 {
    padding-top: 0;
    padding-bottom: 0
}

.pd-lr-0 {
    padding-left: 0;
    padding-right: 0
}

.mr-0 {
    margin: 0
}

.mr-t-0 {
    margin-top: 0
}

.mr-b-0 {
    margin-bottom: 0
}

.mr-l-0 {
    margin-left: 0
}

.mr-r-0 {
    margin-right: 0
}

.mr-tb-0 {
    margin-top: 0;
    margin-bottom: 0
}

.mr-lr-0 {
    margin-left: 0;
    margin-right: 0
}

.pd-1 {
    padding: .5rem
}

.pd-t-1 {
    padding-top: .5rem
}

.pd-b-1 {
    padding-bottom: .5rem
}

.pd-l-1 {
    padding-left: .5rem
}

.pd-r-1 {
    padding-right: .5rem
}

.pd-tb-1 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.pd-lr-1 {
    padding-left: .5rem;
    padding-right: .5rem
}

.mr-1 {
    margin: .5rem
}

.mr-t-1 {
    margin-top: .5rem
}

.mr-b-1 {
    margin-bottom: .5rem
}

.mr-l-1 {
    margin-left: .5rem
}

.mr-r-1 {
    margin-right: .5rem
}

.mr-tb-1 {
    margin-top: .5rem;
    margin-bottom: .5rem
}

.mr-lr-1 {
    margin-left: .5rem;
    margin-right: .5rem
}

.pd-2 {
    padding: 1rem
}

.pd-t-2 {
    padding-top: 1rem
}

.pd-b-2 {
    padding-bottom: 1rem
}

.pd-l-2 {
    padding-left: 1rem
}

.pd-r-2 {
    padding-right: 1rem
}

.pd-tb-2 {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.pd-lr-2 {
    padding-left: 1rem;
    padding-right: 1rem
}

.mr-2 {
    margin: 1rem
}

.mr-t-2 {
    margin-top: 1rem
}

.mr-b-2 {
    margin-bottom: 1rem
}

.mr-l-2 {
    margin-left: 1rem
}

.mr-r-2 {
    margin-right: 1rem
}

.mr-tb-2 {
    margin-top: 1rem;
    margin-bottom: 1rem
}

.mr-lr-2 {
    margin-left: 1rem;
    margin-right: 1rem
}

.pd-3 {
    padding: 1.5rem
}

.pd-t-3 {
    padding-top: 1.5rem
}

.pd-b-3 {
    padding-bottom: 1.5rem
}

.pd-l-3 {
    padding-left: 1.5rem
}

.pd-r-3 {
    padding-right: 1.5rem
}

.pd-tb-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.pd-lr-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.mr-3 {
    margin: 1.5rem
}

.mr-t-3 {
    margin-top: 1.5rem
}

.mr-b-3 {
    margin-bottom: 1.5rem
}

.mr-l-3 {
    margin-left: 1.5rem
}

.mr-r-3 {
    margin-right: 1.5rem
}

.mr-tb-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}

.mr-lr-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
}

.pd-4 {
    padding: 2rem
}

.pd-t-4 {
    padding-top: 2rem
}

.pd-b-4 {
    padding-bottom: 2rem
}

.pd-l-4 {
    padding-left: 2rem
}

.pd-r-4 {
    padding-right: 2rem
}

.pd-tb-4 {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.pd-lr-4 {
    padding-left: 2rem;
    padding-right: 2rem
}

.mr-4 {
    margin: 2rem
}

.mr-t-4 {
    margin-top: 2rem
}

.mr-b-4 {
    margin-bottom: 2rem
}

.mr-l-4 {
    margin-left: 2rem
}

.mr-r-4 {
    margin-right: 2rem
}

.mr-tb-4 {
    margin-top: 2rem;
    margin-bottom: 2rem
}

.mr-lr-4 {
    margin-left: 2rem;
    margin-right: 2rem
}

.pd-5 {
    padding: 2.5rem
}

.pd-t-5 {
    padding-top: 2.5rem
}

.pd-b-5 {
    padding-bottom: 2.5rem
}

.pd-l-5 {
    padding-left: 2.5rem
}

.pd-r-5 {
    padding-right: 2.5rem
}

.pd-tb-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
}

.pd-lr-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
}

.mr-5 {
    margin: 2.5rem
}

.mr-t-5 {
    margin-top: 2.5rem
}

.mr-b-5 {
    margin-bottom: 2.5rem
}

.mr-l-5 {
    margin-left: 2.5rem
}

.mr-r-5 {
    margin-right: 2.5rem
}

.mr-tb-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
}

.mr-lr-5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
}

.pd-6 {
    padding: 3rem
}

.pd-t-6 {
    padding-top: 3rem
}

.pd-b-6 {
    padding-bottom: 3rem
}

.pd-l-6 {
    padding-left: 3rem
}

.pd-r-6 {
    padding-right: 3rem
}

.pd-tb-6 {
    padding-top: 3rem;
    padding-bottom: 3rem
}

.pd-lr-6 {
    padding-left: 3rem;
    padding-right: 3rem
}

.mr-6 {
    margin: 3rem
}

.mr-t-6 {
    margin-top: 3rem
}

.mr-b-6 {
    margin-bottom: 3rem
}

.mr-l-6 {
    margin-left: 3rem
}

.mr-r-6 {
    margin-right: 3rem
}

.mr-tb-6 {
    margin-top: 3rem;
    margin-bottom: 3rem
}

.mr-lr-6 {
    margin-left: 3rem;
    margin-right: 3rem
}

.pd-7 {
    padding: 3.5rem
}

.pd-t-7 {
    padding-top: 3.5rem
}

.pd-b-7 {
    padding-bottom: 3.5rem
}

.pd-l-7 {
    padding-left: 3.5rem
}

.pd-r-7 {
    padding-right: 3.5rem
}

.pd-tb-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
}

.pd-lr-7 {
    padding-left: 3.5rem;
    padding-right: 3.5rem
}

.mr-7 {
    margin: 3.5rem
}

.mr-t-7 {
    margin-top: 3.5rem
}

.mr-b-7 {
    margin-bottom: 3.5rem
}

.mr-l-7 {
    margin-left: 3.5rem
}

.mr-r-7 {
    margin-right: 3.5rem
}

.mr-tb-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
}

.mr-lr-7 {
    margin-left: 3.5rem;
    margin-right: 3.5rem
}

.pd-8 {
    padding: 4rem
}

.pd-t-8 {
    padding-top: 4rem
}

.pd-b-8 {
    padding-bottom: 4rem
}

.pd-l-8 {
    padding-left: 4rem
}

.pd-r-8 {
    padding-right: 4rem
}

.pd-tb-8 {
    padding-top: 4rem;
    padding-bottom: 4rem
}

.pd-lr-8 {
    padding-left: 4rem;
    padding-right: 4rem
}

.mr-8 {
    margin: 4rem
}

.mr-t-8 {
    margin-top: 4rem
}

.mr-b-8 {
    margin-bottom: 4rem
}

.mr-l-8 {
    margin-left: 4rem
}

.mr-r-8 {
    margin-right: 4rem
}

.mr-tb-8 {
    margin-top: 4rem;
    margin-bottom: 4rem
}

.mr-lr-8 {
    margin-left: 4rem;
    margin-right: 4rem
}

.pd-9 {
    padding: 4.5rem
}

.pd-t-9 {
    padding-top: 4.5rem
}

.pd-b-9 {
    padding-bottom: 4.5rem
}

.pd-l-9 {
    padding-left: 4.5rem
}

.pd-r-9 {
    padding-right: 4.5rem
}

.pd-tb-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem
}

.pd-lr-9 {
    padding-left: 4.5rem;
    padding-right: 4.5rem
}

.mr-9 {
    margin: 4.5rem
}

.mr-t-9 {
    margin-top: 4.5rem
}

.mr-b-9 {
    margin-bottom: 4.5rem
}

.mr-l-9 {
    margin-left: 4.5rem
}

.mr-r-9 {
    margin-right: 4.5rem
}

.mr-tb-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem
}

.mr-lr-9 {
    margin-left: 4.5rem;
    margin-right: 4.5rem
}

.pd-10 {
    padding: 5rem
}

.pd-t-10 {
    padding-top: 5rem
}

.pd-b-10 {
    padding-bottom: 5rem
}

.pd-l-10 {
    padding-left: 5rem
}

.pd-r-10 {
    padding-right: 5rem
}

.pd-tb-10 {
    padding-top: 5rem;
    padding-bottom: 5rem
}

.pd-lr-10 {
    padding-left: 5rem;
    padding-right: 5rem
}

.mr-10 {
    margin: 5rem
}

.mr-t-10 {
    margin-top: 5rem
}

.mr-b-10 {
    margin-bottom: 5rem
}

.mr-l-10 {
    margin-left: 5rem
}

.mr-r-10 {
    margin-right: 5rem
}

.mr-tb-10 {
    margin-top: 5rem;
    margin-bottom: 5rem
}

.mr-lr-10 {
    margin-left: 5rem;
    margin-right: 5rem
}

@media (min-width:576px) {
    .pd-sm-0 {
        padding: 0
    }

    .pd-sm-t-0 {
        padding-top: 0
    }

    .pd-sm-b-0 {
        padding-bottom: 0
    }

    .pd-sm-l-0 {
        padding-left: 0
    }

    .pd-sm-r-0 {
        padding-right: 0
    }

    .pd-sm-tb-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    .pd-sm-lr-0 {
        padding-left: 0;
        padding-right: 0
    }

    .mr-sm-0 {
        margin: 0
    }

    .mr-sm-t-0 {
        margin-top: 0
    }

    .mr-sm-b-0 {
        margin-bottom: 0
    }

    .mr-sm-l-0 {
        margin-left: 0
    }

    .mr-sm-r-0 {
        margin-right: 0
    }

    .mr-sm-tb-0 {
        margin-top: 0;
        margin-bottom: 0
    }

    .mr-sm-lr-0 {
        margin-left: 0;
        margin-right: 0
    }

    .pd-sm-1 {
        padding: .5rem
    }

    .pd-sm-t-1 {
        padding-top: .5rem
    }

    .pd-sm-b-1 {
        padding-bottom: .5rem
    }

    .pd-sm-l-1 {
        padding-left: .5rem
    }

    .pd-sm-r-1 {
        padding-right: .5rem
    }

    .pd-sm-tb-1 {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .pd-sm-lr-1 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .mr-sm-1 {
        margin: .5rem
    }

    .mr-sm-t-1 {
        margin-top: .5rem
    }

    .mr-sm-b-1 {
        margin-bottom: .5rem
    }

    .mr-sm-l-1 {
        margin-left: .5rem
    }

    .mr-sm-r-1 {
        margin-right: .5rem
    }

    .mr-sm-tb-1 {
        margin-top: .5rem;
        margin-bottom: .5rem
    }

    .mr-sm-lr-1 {
        margin-left: .5rem;
        margin-right: .5rem
    }

    .pd-sm-2 {
        padding: 1rem
    }

    .pd-sm-t-2 {
        padding-top: 1rem
    }

    .pd-sm-b-2 {
        padding-bottom: 1rem
    }

    .pd-sm-l-2 {
        padding-left: 1rem
    }

    .pd-sm-r-2 {
        padding-right: 1rem
    }

    .pd-sm-tb-2 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .pd-sm-lr-2 {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .mr-sm-2 {
        margin: 1rem
    }

    .mr-sm-t-2 {
        margin-top: 1rem
    }

    .mr-sm-b-2 {
        margin-bottom: 1rem
    }

    .mr-sm-l-2 {
        margin-left: 1rem
    }

    .mr-sm-r-2 {
        margin-right: 1rem
    }

    .mr-sm-tb-2 {
        margin-top: 1rem;
        margin-bottom: 1rem
    }

    .mr-sm-lr-2 {
        margin-left: 1rem;
        margin-right: 1rem
    }

    .pd-sm-3 {
        padding: 1.5rem
    }

    .pd-sm-t-3 {
        padding-top: 1.5rem
    }

    .pd-sm-b-3 {
        padding-bottom: 1.5rem
    }

    .pd-sm-l-3 {
        padding-left: 1.5rem
    }

    .pd-sm-r-3 {
        padding-right: 1.5rem
    }

    .pd-sm-tb-3 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .pd-sm-lr-3 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .mr-sm-3 {
        margin: 1.5rem
    }

    .mr-sm-t-3 {
        margin-top: 1.5rem
    }

    .mr-sm-b-3 {
        margin-bottom: 1.5rem
    }

    .mr-sm-l-3 {
        margin-left: 1.5rem
    }

    .mr-sm-r-3 {
        margin-right: 1.5rem
    }

    .mr-sm-tb-3 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem
    }

    .mr-sm-lr-3 {
        margin-left: 1.5rem;
        margin-right: 1.5rem
    }

    .pd-sm-4 {
        padding: 2rem
    }

    .pd-sm-t-4 {
        padding-top: 2rem
    }

    .pd-sm-b-4 {
        padding-bottom: 2rem
    }

    .pd-sm-l-4 {
        padding-left: 2rem
    }

    .pd-sm-r-4 {
        padding-right: 2rem
    }

    .pd-sm-tb-4 {
        padding-top: 2rem;
        padding-bottom: 2rem
    }

    .pd-sm-lr-4 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .mr-sm-4 {
        margin: 2rem
    }

    .mr-sm-t-4 {
        margin-top: 2rem
    }

    .mr-sm-b-4 {
        margin-bottom: 2rem
    }

    .mr-sm-l-4 {
        margin-left: 2rem
    }

    .mr-sm-r-4 {
        margin-right: 2rem
    }

    .mr-sm-tb-4 {
        margin-top: 2rem;
        margin-bottom: 2rem
    }

    .mr-sm-lr-4 {
        margin-left: 2rem;
        margin-right: 2rem
    }

    .pd-sm-5 {
        padding: 2.5rem
    }

    .pd-sm-t-5 {
        padding-top: 2.5rem
    }

    .pd-sm-b-5 {
        padding-bottom: 2.5rem
    }

    .pd-sm-l-5 {
        padding-left: 2.5rem
    }

    .pd-sm-r-5 {
        padding-right: 2.5rem
    }

    .pd-sm-tb-5 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .pd-sm-lr-5 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .mr-sm-5 {
        margin: 2.5rem
    }

    .mr-sm-t-5 {
        margin-top: 2.5rem
    }

    .mr-sm-b-5 {
        margin-bottom: 2.5rem
    }

    .mr-sm-l-5 {
        margin-left: 2.5rem
    }

    .mr-sm-r-5 {
        margin-right: 2.5rem
    }

    .mr-sm-tb-5 {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem
    }

    .mr-sm-lr-5 {
        margin-left: 2.5rem;
        margin-right: 2.5rem
    }

    .pd-sm-6 {
        padding: 3rem
    }

    .pd-sm-t-6 {
        padding-top: 3rem
    }

    .pd-sm-b-6 {
        padding-bottom: 3rem
    }

    .pd-sm-l-6 {
        padding-left: 3rem
    }

    .pd-sm-r-6 {
        padding-right: 3rem
    }

    .pd-sm-tb-6 {
        padding-top: 3rem;
        padding-bottom: 3rem
    }

    .pd-sm-lr-6 {
        padding-left: 3rem;
        padding-right: 3rem
    }

    .mr-sm-6 {
        margin: 3rem
    }

    .mr-sm-t-6 {
        margin-top: 3rem
    }

    .mr-sm-b-6 {
        margin-bottom: 3rem
    }

    .mr-sm-l-6 {
        margin-left: 3rem
    }

    .mr-sm-r-6 {
        margin-right: 3rem
    }

    .mr-sm-tb-6 {
        margin-top: 3rem;
        margin-bottom: 3rem
    }

    .mr-sm-lr-6 {
        margin-left: 3rem;
        margin-right: 3rem
    }

    .pd-sm-7 {
        padding: 3.5rem
    }

    .pd-sm-t-7 {
        padding-top: 3.5rem
    }

    .pd-sm-b-7 {
        padding-bottom: 3.5rem
    }

    .pd-sm-l-7 {
        padding-left: 3.5rem
    }

    .pd-sm-r-7 {
        padding-right: 3.5rem
    }

    .pd-sm-tb-7 {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem
    }

    .pd-sm-lr-7 {
        padding-left: 3.5rem;
        padding-right: 3.5rem
    }

    .mr-sm-7 {
        margin: 3.5rem
    }

    .mr-sm-t-7 {
        margin-top: 3.5rem
    }

    .mr-sm-b-7 {
        margin-bottom: 3.5rem
    }

    .mr-sm-l-7 {
        margin-left: 3.5rem
    }

    .mr-sm-r-7 {
        margin-right: 3.5rem
    }

    .mr-sm-tb-7 {
        margin-top: 3.5rem;
        margin-bottom: 3.5rem
    }

    .mr-sm-lr-7 {
        margin-left: 3.5rem;
        margin-right: 3.5rem
    }

    .pd-sm-8 {
        padding: 4rem
    }

    .pd-sm-t-8 {
        padding-top: 4rem
    }

    .pd-sm-b-8 {
        padding-bottom: 4rem
    }

    .pd-sm-l-8 {
        padding-left: 4rem
    }

    .pd-sm-r-8 {
        padding-right: 4rem
    }

    .pd-sm-tb-8 {
        padding-top: 4rem;
        padding-bottom: 4rem
    }

    .pd-sm-lr-8 {
        padding-left: 4rem;
        padding-right: 4rem
    }

    .mr-sm-8 {
        margin: 4rem
    }

    .mr-sm-t-8 {
        margin-top: 4rem
    }

    .mr-sm-b-8 {
        margin-bottom: 4rem
    }

    .mr-sm-l-8 {
        margin-left: 4rem
    }

    .mr-sm-r-8 {
        margin-right: 4rem
    }

    .mr-sm-tb-8 {
        margin-top: 4rem;
        margin-bottom: 4rem
    }

    .mr-sm-lr-8 {
        margin-left: 4rem;
        margin-right: 4rem
    }

    .pd-sm-9 {
        padding: 4.5rem
    }

    .pd-sm-t-9 {
        padding-top: 4.5rem
    }

    .pd-sm-b-9 {
        padding-bottom: 4.5rem
    }

    .pd-sm-l-9 {
        padding-left: 4.5rem
    }

    .pd-sm-r-9 {
        padding-right: 4.5rem
    }

    .pd-sm-tb-9 {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem
    }

    .pd-sm-lr-9 {
        padding-left: 4.5rem;
        padding-right: 4.5rem
    }

    .mr-sm-9 {
        margin: 4.5rem
    }

    .mr-sm-t-9 {
        margin-top: 4.5rem
    }

    .mr-sm-b-9 {
        margin-bottom: 4.5rem
    }

    .mr-sm-l-9 {
        margin-left: 4.5rem
    }

    .mr-sm-r-9 {
        margin-right: 4.5rem
    }

    .mr-sm-tb-9 {
        margin-top: 4.5rem;
        margin-bottom: 4.5rem
    }

    .mr-sm-lr-9 {
        margin-left: 4.5rem;
        margin-right: 4.5rem
    }

    .pd-sm-10 {
        padding: 5rem
    }

    .pd-sm-t-10 {
        padding-top: 5rem
    }

    .pd-sm-b-10 {
        padding-bottom: 5rem
    }

    .pd-sm-l-10 {
        padding-left: 5rem
    }

    .pd-sm-r-10 {
        padding-right: 5rem
    }

    .pd-sm-tb-10 {
        padding-top: 5rem;
        padding-bottom: 5rem
    }

    .pd-sm-lr-10 {
        padding-left: 5rem;
        padding-right: 5rem
    }

    .mr-sm-10 {
        margin: 5rem
    }

    .mr-sm-t-10 {
        margin-top: 5rem
    }

    .mr-sm-b-10 {
        margin-bottom: 5rem
    }

    .mr-sm-l-10 {
        margin-left: 5rem
    }

    .mr-sm-r-10 {
        margin-right: 5rem
    }

    .mr-sm-tb-10 {
        margin-top: 5rem;
        margin-bottom: 5rem
    }

    .mr-sm-lr-10 {
        margin-left: 5rem;
        margin-right: 5rem
    }
}

@media (min-width:768px) {
    .pd-md-0 {
        padding: 0
    }

    .pd-md-t-0 {
        padding-top: 0
    }

    .pd-md-b-0 {
        padding-bottom: 0
    }

    .pd-md-l-0 {
        padding-left: 0
    }

    .pd-md-r-0 {
        padding-right: 0
    }

    .pd-md-tb-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    .pd-md-lr-0 {
        padding-left: 0;
        padding-right: 0
    }

    .mr-md-0 {
        margin: 0
    }

    .mr-md-t-0 {
        margin-top: 0
    }

    .mr-md-b-0 {
        margin-bottom: 0
    }

    .mr-md-l-0 {
        margin-left: 0
    }

    .mr-md-r-0 {
        margin-right: 0
    }

    .mr-md-tb-0 {
        margin-top: 0;
        margin-bottom: 0
    }

    .mr-md-lr-0 {
        margin-left: 0;
        margin-right: 0
    }

    .pd-md-1 {
        padding: .5rem
    }

    .pd-md-t-1 {
        padding-top: .5rem
    }

    .pd-md-b-1 {
        padding-bottom: .5rem
    }

    .pd-md-l-1 {
        padding-left: .5rem
    }

    .pd-md-r-1 {
        padding-right: .5rem
    }

    .pd-md-tb-1 {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .pd-md-lr-1 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .mr-md-1 {
        margin: .5rem
    }

    .mr-md-t-1 {
        margin-top: .5rem
    }

    .mr-md-b-1 {
        margin-bottom: .5rem
    }

    .mr-md-l-1 {
        margin-left: .5rem
    }

    .mr-md-r-1 {
        margin-right: .5rem
    }

    .mr-md-tb-1 {
        margin-top: .5rem;
        margin-bottom: .5rem
    }

    .mr-md-lr-1 {
        margin-left: .5rem;
        margin-right: .5rem
    }

    .pd-md-2 {
        padding: 1rem
    }

    .pd-md-t-2 {
        padding-top: 1rem
    }

    .pd-md-b-2 {
        padding-bottom: 1rem
    }

    .pd-md-l-2 {
        padding-left: 1rem
    }

    .pd-md-r-2 {
        padding-right: 1rem
    }

    .pd-md-tb-2 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .pd-md-lr-2 {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .mr-md-2 {
        margin: 1rem
    }

    .mr-md-t-2 {
        margin-top: 1rem
    }

    .mr-md-b-2 {
        margin-bottom: 1rem
    }

    .mr-md-l-2 {
        margin-left: 1rem
    }

    .mr-md-r-2 {
        margin-right: 1rem
    }

    .mr-md-tb-2 {
        margin-top: 1rem;
        margin-bottom: 1rem
    }

    .mr-md-lr-2 {
        margin-left: 1rem;
        margin-right: 1rem
    }

    .pd-md-3 {
        padding: 1.5rem
    }

    .pd-md-t-3 {
        padding-top: 1.5rem
    }

    .pd-md-b-3 {
        padding-bottom: 1.5rem
    }

    .pd-md-l-3 {
        padding-left: 1.5rem
    }

    .pd-md-r-3 {
        padding-right: 1.5rem
    }

    .pd-md-tb-3 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .pd-md-lr-3 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .mr-md-3 {
        margin: 1.5rem
    }

    .mr-md-t-3 {
        margin-top: 1.5rem
    }

    .mr-md-b-3 {
        margin-bottom: 1.5rem
    }

    .mr-md-l-3 {
        margin-left: 1.5rem
    }

    .mr-md-r-3 {
        margin-right: 1.5rem
    }

    .mr-md-tb-3 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem
    }

    .mr-md-lr-3 {
        margin-left: 1.5rem;
        margin-right: 1.5rem
    }

    .pd-md-4 {
        padding: 2rem
    }

    .pd-md-t-4 {
        padding-top: 2rem
    }

    .pd-md-b-4 {
        padding-bottom: 2rem
    }

    .pd-md-l-4 {
        padding-left: 2rem
    }

    .pd-md-r-4 {
        padding-right: 2rem
    }

    .pd-md-tb-4 {
        padding-top: 2rem;
        padding-bottom: 2rem
    }

    .pd-md-lr-4 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .mr-md-4 {
        margin: 2rem
    }

    .mr-md-t-4 {
        margin-top: 2rem
    }

    .mr-md-b-4 {
        margin-bottom: 2rem
    }

    .mr-md-l-4 {
        margin-left: 2rem
    }

    .mr-md-r-4 {
        margin-right: 2rem
    }

    .mr-md-tb-4 {
        margin-top: 2rem;
        margin-bottom: 2rem
    }

    .mr-md-lr-4 {
        margin-left: 2rem;
        margin-right: 2rem
    }

    .pd-md-5 {
        padding: 2.5rem
    }

    .pd-md-t-5 {
        padding-top: 2.5rem
    }

    .pd-md-b-5 {
        padding-bottom: 2.5rem
    }

    .pd-md-l-5 {
        padding-left: 2.5rem
    }

    .pd-md-r-5 {
        padding-right: 2.5rem
    }

    .pd-md-tb-5 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .pd-md-lr-5 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .mr-md-5 {
        margin: 2.5rem
    }

    .mr-md-t-5 {
        margin-top: 2.5rem
    }

    .mr-md-b-5 {
        margin-bottom: 2.5rem
    }

    .mr-md-l-5 {
        margin-left: 2.5rem
    }

    .mr-md-r-5 {
        margin-right: 2.5rem
    }

    .mr-md-tb-5 {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem
    }

    .mr-md-lr-5 {
        margin-left: 2.5rem;
        margin-right: 2.5rem
    }

    .pd-md-6 {
        padding: 3rem
    }

    .pd-md-t-6 {
        padding-top: 3rem
    }

    .pd-md-b-6 {
        padding-bottom: 3rem
    }

    .pd-md-l-6 {
        padding-left: 3rem
    }

    .pd-md-r-6 {
        padding-right: 3rem
    }

    .pd-md-tb-6 {
        padding-top: 3rem;
        padding-bottom: 3rem
    }

    .pd-md-lr-6 {
        padding-left: 3rem;
        padding-right: 3rem
    }

    .mr-md-6 {
        margin: 3rem
    }

    .mr-md-t-6 {
        margin-top: 3rem
    }

    .mr-md-b-6 {
        margin-bottom: 3rem
    }

    .mr-md-l-6 {
        margin-left: 3rem
    }

    .mr-md-r-6 {
        margin-right: 3rem
    }

    .mr-md-tb-6 {
        margin-top: 3rem;
        margin-bottom: 3rem
    }

    .mr-md-lr-6 {
        margin-left: 3rem;
        margin-right: 3rem
    }

    .pd-md-7 {
        padding: 3.5rem
    }

    .pd-md-t-7 {
        padding-top: 3.5rem
    }

    .pd-md-b-7 {
        padding-bottom: 3.5rem
    }

    .pd-md-l-7 {
        padding-left: 3.5rem
    }

    .pd-md-r-7 {
        padding-right: 3.5rem
    }

    .pd-md-tb-7 {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem
    }

    .pd-md-lr-7 {
        padding-left: 3.5rem;
        padding-right: 3.5rem
    }

    .mr-md-7 {
        margin: 3.5rem
    }

    .mr-md-t-7 {
        margin-top: 3.5rem
    }

    .mr-md-b-7 {
        margin-bottom: 3.5rem
    }

    .mr-md-l-7 {
        margin-left: 3.5rem
    }

    .mr-md-r-7 {
        margin-right: 3.5rem
    }

    .mr-md-tb-7 {
        margin-top: 3.5rem;
        margin-bottom: 3.5rem
    }

    .mr-md-lr-7 {
        margin-left: 3.5rem;
        margin-right: 3.5rem
    }

    .pd-md-8 {
        padding: 4rem
    }

    .pd-md-t-8 {
        padding-top: 4rem
    }

    .pd-md-b-8 {
        padding-bottom: 4rem
    }

    .pd-md-l-8 {
        padding-left: 4rem
    }

    .pd-md-r-8 {
        padding-right: 4rem
    }

    .pd-md-tb-8 {
        padding-top: 4rem;
        padding-bottom: 4rem
    }

    .pd-md-lr-8 {
        padding-left: 4rem;
        padding-right: 4rem
    }

    .mr-md-8 {
        margin: 4rem
    }

    .mr-md-t-8 {
        margin-top: 4rem
    }

    .mr-md-b-8 {
        margin-bottom: 4rem
    }

    .mr-md-l-8 {
        margin-left: 4rem
    }

    .mr-md-r-8 {
        margin-right: 4rem
    }

    .mr-md-tb-8 {
        margin-top: 4rem;
        margin-bottom: 4rem
    }

    .mr-md-lr-8 {
        margin-left: 4rem;
        margin-right: 4rem
    }

    .pd-md-9 {
        padding: 4.5rem
    }

    .pd-md-t-9 {
        padding-top: 4.5rem
    }

    .pd-md-b-9 {
        padding-bottom: 4.5rem
    }

    .pd-md-l-9 {
        padding-left: 4.5rem
    }

    .pd-md-r-9 {
        padding-right: 4.5rem
    }

    .pd-md-tb-9 {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem
    }

    .pd-md-lr-9 {
        padding-left: 4.5rem;
        padding-right: 4.5rem
    }

    .mr-md-9 {
        margin: 4.5rem
    }

    .mr-md-t-9 {
        margin-top: 4.5rem
    }

    .mr-md-b-9 {
        margin-bottom: 4.5rem
    }

    .mr-md-l-9 {
        margin-left: 4.5rem
    }

    .mr-md-r-9 {
        margin-right: 4.5rem
    }

    .mr-md-tb-9 {
        margin-top: 4.5rem;
        margin-bottom: 4.5rem
    }

    .mr-md-lr-9 {
        margin-left: 4.5rem;
        margin-right: 4.5rem
    }

    .pd-md-10 {
        padding: 5rem
    }

    .pd-md-t-10 {
        padding-top: 5rem
    }

    .pd-md-b-10 {
        padding-bottom: 5rem
    }

    .pd-md-l-10 {
        padding-left: 5rem
    }

    .pd-md-r-10 {
        padding-right: 5rem
    }

    .pd-md-tb-10 {
        padding-top: 5rem;
        padding-bottom: 5rem
    }

    .pd-md-lr-10 {
        padding-left: 5rem;
        padding-right: 5rem
    }

    .mr-md-10 {
        margin: 5rem
    }

    .mr-md-t-10 {
        margin-top: 5rem
    }

    .mr-md-b-10 {
        margin-bottom: 5rem
    }

    .mr-md-l-10 {
        margin-left: 5rem
    }

    .mr-md-r-10 {
        margin-right: 5rem
    }

    .mr-md-tb-10 {
        margin-top: 5rem;
        margin-bottom: 5rem
    }

    .mr-md-lr-10 {
        margin-left: 5rem;
        margin-right: 5rem
    }
}

@media (min-width:992px) {
    .pd-lg-0 {
        padding: 0
    }

    .pd-lg-t-0 {
        padding-top: 0
    }

    .pd-lg-b-0 {
        padding-bottom: 0
    }

    .pd-lg-l-0 {
        padding-left: 0
    }

    .pd-lg-r-0 {
        padding-right: 0
    }

    .pd-lg-tb-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    .pd-lg-lr-0 {
        padding-left: 0;
        padding-right: 0
    }

    .mr-lg-0 {
        margin: 0
    }

    .mr-lg-t-0 {
        margin-top: 0
    }

    .mr-lg-b-0 {
        margin-bottom: 0
    }

    .mr-lg-l-0 {
        margin-left: 0
    }

    .mr-lg-r-0 {
        margin-right: 0
    }

    .mr-lg-tb-0 {
        margin-top: 0;
        margin-bottom: 0
    }

    .mr-lg-lr-0 {
        margin-left: 0;
        margin-right: 0
    }

    .pd-lg-1 {
        padding: .5rem
    }

    .pd-lg-t-1 {
        padding-top: .5rem
    }

    .pd-lg-b-1 {
        padding-bottom: .5rem
    }

    .pd-lg-l-1 {
        padding-left: .5rem
    }

    .pd-lg-r-1 {
        padding-right: .5rem
    }

    .pd-lg-tb-1 {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .pd-lg-lr-1 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .mr-lg-1 {
        margin: .5rem
    }

    .mr-lg-t-1 {
        margin-top: .5rem
    }

    .mr-lg-b-1 {
        margin-bottom: .5rem
    }

    .mr-lg-l-1 {
        margin-left: .5rem
    }

    .mr-lg-r-1 {
        margin-right: .5rem
    }

    .mr-lg-tb-1 {
        margin-top: .5rem;
        margin-bottom: .5rem
    }

    .mr-lg-lr-1 {
        margin-left: .5rem;
        margin-right: .5rem
    }

    .pd-lg-2 {
        padding: 1rem
    }

    .pd-lg-t-2 {
        padding-top: 1rem
    }

    .pd-lg-b-2 {
        padding-bottom: 1rem
    }

    .pd-lg-l-2 {
        padding-left: 1rem
    }

    .pd-lg-r-2 {
        padding-right: 1rem
    }

    .pd-lg-tb-2 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .pd-lg-lr-2 {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .mr-lg-2 {
        margin: 1rem
    }

    .mr-lg-t-2 {
        margin-top: 1rem
    }

    .mr-lg-b-2 {
        margin-bottom: 1rem
    }

    .mr-lg-l-2 {
        margin-left: 1rem
    }

    .mr-lg-r-2 {
        margin-right: 1rem
    }

    .mr-lg-tb-2 {
        margin-top: 1rem;
        margin-bottom: 1rem
    }

    .mr-lg-lr-2 {
        margin-left: 1rem;
        margin-right: 1rem
    }

    .pd-lg-3 {
        padding: 1.5rem
    }

    .pd-lg-t-3 {
        padding-top: 1.5rem
    }

    .pd-lg-b-3 {
        padding-bottom: 1.5rem
    }

    .pd-lg-l-3 {
        padding-left: 1.5rem
    }

    .pd-lg-r-3 {
        padding-right: 1.5rem
    }

    .pd-lg-tb-3 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .pd-lg-lr-3 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .mr-lg-3 {
        margin: 1.5rem
    }

    .mr-lg-t-3 {
        margin-top: 1.5rem
    }

    .mr-lg-b-3 {
        margin-bottom: 1.5rem
    }

    .mr-lg-l-3 {
        margin-left: 1.5rem
    }

    .mr-lg-r-3 {
        margin-right: 1.5rem
    }

    .mr-lg-tb-3 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem
    }

    .mr-lg-lr-3 {
        margin-left: 1.5rem;
        margin-right: 1.5rem
    }

    .pd-lg-4 {
        padding: 2rem
    }

    .pd-lg-t-4 {
        padding-top: 2rem
    }

    .pd-lg-b-4 {
        padding-bottom: 2rem
    }

    .pd-lg-l-4 {
        padding-left: 2rem
    }

    .pd-lg-r-4 {
        padding-right: 2rem
    }

    .pd-lg-tb-4 {
        padding-top: 2rem;
        padding-bottom: 2rem
    }

    .pd-lg-lr-4 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .mr-lg-4 {
        margin: 2rem
    }

    .mr-lg-t-4 {
        margin-top: 2rem
    }

    .mr-lg-b-4 {
        margin-bottom: 2rem
    }

    .mr-lg-l-4 {
        margin-left: 2rem
    }

    .mr-lg-r-4 {
        margin-right: 2rem
    }

    .mr-lg-tb-4 {
        margin-top: 2rem;
        margin-bottom: 2rem
    }

    .mr-lg-lr-4 {
        margin-left: 2rem;
        margin-right: 2rem
    }

    .pd-lg-5 {
        padding: 2.5rem
    }

    .pd-lg-t-5 {
        padding-top: 2.5rem
    }

    .pd-lg-b-5 {
        padding-bottom: 2.5rem
    }

    .pd-lg-l-5 {
        padding-left: 2.5rem
    }

    .pd-lg-r-5 {
        padding-right: 2.5rem
    }

    .pd-lg-tb-5 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .pd-lg-lr-5 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .mr-lg-5 {
        margin: 2.5rem
    }

    .mr-lg-t-5 {
        margin-top: 2.5rem
    }

    .mr-lg-b-5 {
        margin-bottom: 2.5rem
    }

    .mr-lg-l-5 {
        margin-left: 2.5rem
    }

    .mr-lg-r-5 {
        margin-right: 2.5rem
    }

    .mr-lg-tb-5 {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem
    }

    .mr-lg-lr-5 {
        margin-left: 2.5rem;
        margin-right: 2.5rem
    }

    .pd-lg-6 {
        padding: 3rem
    }

    .pd-lg-t-6 {
        padding-top: 3rem
    }

    .pd-lg-b-6 {
        padding-bottom: 3rem
    }

    .pd-lg-l-6 {
        padding-left: 3rem
    }

    .pd-lg-r-6 {
        padding-right: 3rem
    }

    .pd-lg-tb-6 {
        padding-top: 3rem;
        padding-bottom: 3rem
    }

    .pd-lg-lr-6 {
        padding-left: 3rem;
        padding-right: 3rem
    }

    .mr-lg-6 {
        margin: 3rem
    }

    .mr-lg-t-6 {
        margin-top: 3rem
    }

    .mr-lg-b-6 {
        margin-bottom: 3rem
    }

    .mr-lg-l-6 {
        margin-left: 3rem
    }

    .mr-lg-r-6 {
        margin-right: 3rem
    }

    .mr-lg-tb-6 {
        margin-top: 3rem;
        margin-bottom: 3rem
    }

    .mr-lg-lr-6 {
        margin-left: 3rem;
        margin-right: 3rem
    }

    .pd-lg-7 {
        padding: 3.5rem
    }

    .pd-lg-t-7 {
        padding-top: 3.5rem
    }

    .pd-lg-b-7 {
        padding-bottom: 3.5rem
    }

    .pd-lg-l-7 {
        padding-left: 3.5rem
    }

    .pd-lg-r-7 {
        padding-right: 3.5rem
    }

    .pd-lg-tb-7 {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem
    }

    .pd-lg-lr-7 {
        padding-left: 3.5rem;
        padding-right: 3.5rem
    }

    .mr-lg-7 {
        margin: 3.5rem
    }

    .mr-lg-t-7 {
        margin-top: 3.5rem
    }

    .mr-lg-b-7 {
        margin-bottom: 3.5rem
    }

    .mr-lg-l-7 {
        margin-left: 3.5rem
    }

    .mr-lg-r-7 {
        margin-right: 3.5rem
    }

    .mr-lg-tb-7 {
        margin-top: 3.5rem;
        margin-bottom: 3.5rem
    }

    .mr-lg-lr-7 {
        margin-left: 3.5rem;
        margin-right: 3.5rem
    }

    .pd-lg-8 {
        padding: 4rem
    }

    .pd-lg-t-8 {
        padding-top: 4rem
    }

    .pd-lg-b-8 {
        padding-bottom: 4rem
    }

    .pd-lg-l-8 {
        padding-left: 4rem
    }

    .pd-lg-r-8 {
        padding-right: 4rem
    }

    .pd-lg-tb-8 {
        padding-top: 4rem;
        padding-bottom: 4rem
    }

    .pd-lg-lr-8 {
        padding-left: 4rem;
        padding-right: 4rem
    }

    .mr-lg-8 {
        margin: 4rem
    }

    .mr-lg-t-8 {
        margin-top: 4rem
    }

    .mr-lg-b-8 {
        margin-bottom: 4rem
    }

    .mr-lg-l-8 {
        margin-left: 4rem
    }

    .mr-lg-r-8 {
        margin-right: 4rem
    }

    .mr-lg-tb-8 {
        margin-top: 4rem;
        margin-bottom: 4rem
    }

    .mr-lg-lr-8 {
        margin-left: 4rem;
        margin-right: 4rem
    }

    .pd-lg-9 {
        padding: 4.5rem
    }

    .pd-lg-t-9 {
        padding-top: 4.5rem
    }

    .pd-lg-b-9 {
        padding-bottom: 4.5rem
    }

    .pd-lg-l-9 {
        padding-left: 4.5rem
    }

    .pd-lg-r-9 {
        padding-right: 4.5rem
    }

    .pd-lg-tb-9 {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem
    }

    .pd-lg-lr-9 {
        padding-left: 4.5rem;
        padding-right: 4.5rem
    }

    .mr-lg-9 {
        margin: 4.5rem
    }

    .mr-lg-t-9 {
        margin-top: 4.5rem
    }

    .mr-lg-b-9 {
        margin-bottom: 4.5rem
    }

    .mr-lg-l-9 {
        margin-left: 4.5rem
    }

    .mr-lg-r-9 {
        margin-right: 4.5rem
    }

    .mr-lg-tb-9 {
        margin-top: 4.5rem;
        margin-bottom: 4.5rem
    }

    .mr-lg-lr-9 {
        margin-left: 4.5rem;
        margin-right: 4.5rem
    }

    .pd-lg-10 {
        padding: 5rem
    }

    .pd-lg-t-10 {
        padding-top: 5rem
    }

    .pd-lg-b-10 {
        padding-bottom: 5rem
    }

    .pd-lg-l-10 {
        padding-left: 5rem
    }

    .pd-lg-r-10 {
        padding-right: 5rem
    }

    .pd-lg-tb-10 {
        padding-top: 5rem;
        padding-bottom: 5rem
    }

    .pd-lg-lr-10 {
        padding-left: 5rem;
        padding-right: 5rem
    }

    .mr-lg-10 {
        margin: 5rem
    }

    .mr-lg-t-10 {
        margin-top: 5rem
    }

    .mr-lg-b-10 {
        margin-bottom: 5rem
    }

    .mr-lg-l-10 {
        margin-left: 5rem
    }

    .mr-lg-r-10 {
        margin-right: 5rem
    }

    .mr-lg-tb-10 {
        margin-top: 5rem;
        margin-bottom: 5rem
    }

    .mr-lg-lr-10 {
        margin-left: 5rem;
        margin-right: 5rem
    }
}

@media (min-width:1200px) {
    .pd-xl-0 {
        padding: 0
    }

    .pd-xl-t-0 {
        padding-top: 0
    }

    .pd-xl-b-0 {
        padding-bottom: 0
    }

    .pd-xl-l-0 {
        padding-left: 0
    }

    .pd-xl-r-0 {
        padding-right: 0
    }

    .pd-xl-tb-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    .pd-xl-lr-0 {
        padding-left: 0;
        padding-right: 0
    }

    .mr-xl-0 {
        margin: 0
    }

    .mr-xl-t-0 {
        margin-top: 0
    }

    .mr-xl-b-0 {
        margin-bottom: 0
    }

    .mr-xl-l-0 {
        margin-left: 0
    }

    .mr-xl-r-0 {
        margin-right: 0
    }

    .mr-xl-tb-0 {
        margin-top: 0;
        margin-bottom: 0
    }

    .mr-xl-lr-0 {
        margin-left: 0;
        margin-right: 0
    }

    .pd-xl-1 {
        padding: .5rem
    }

    .pd-xl-t-1 {
        padding-top: .5rem
    }

    .pd-xl-b-1 {
        padding-bottom: .5rem
    }

    .pd-xl-l-1 {
        padding-left: .5rem
    }

    .pd-xl-r-1 {
        padding-right: .5rem
    }

    .pd-xl-tb-1 {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .pd-xl-lr-1 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .mr-xl-1 {
        margin: .5rem
    }

    .mr-xl-t-1 {
        margin-top: .5rem
    }

    .mr-xl-b-1 {
        margin-bottom: .5rem
    }

    .mr-xl-l-1 {
        margin-left: .5rem
    }

    .mr-xl-r-1 {
        margin-right: .5rem
    }

    .mr-xl-tb-1 {
        margin-top: .5rem;
        margin-bottom: .5rem
    }

    .mr-xl-lr-1 {
        margin-left: .5rem;
        margin-right: .5rem
    }

    .pd-xl-2 {
        padding: 1rem
    }

    .pd-xl-t-2 {
        padding-top: 1rem
    }

    .pd-xl-b-2 {
        padding-bottom: 1rem
    }

    .pd-xl-l-2 {
        padding-left: 1rem
    }

    .pd-xl-r-2 {
        padding-right: 1rem
    }

    .pd-xl-tb-2 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .pd-xl-lr-2 {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .mr-xl-2 {
        margin: 1rem
    }

    .mr-xl-t-2 {
        margin-top: 1rem
    }

    .mr-xl-b-2 {
        margin-bottom: 1rem
    }

    .mr-xl-l-2 {
        margin-left: 1rem
    }

    .mr-xl-r-2 {
        margin-right: 1rem
    }

    .mr-xl-tb-2 {
        margin-top: 1rem;
        margin-bottom: 1rem
    }

    .mr-xl-lr-2 {
        margin-left: 1rem;
        margin-right: 1rem
    }

    .pd-xl-3 {
        padding: 1.5rem
    }

    .pd-xl-t-3 {
        padding-top: 1.5rem
    }

    .pd-xl-b-3 {
        padding-bottom: 1.5rem
    }

    .pd-xl-l-3 {
        padding-left: 1.5rem
    }

    .pd-xl-r-3 {
        padding-right: 1.5rem
    }

    .pd-xl-tb-3 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .pd-xl-lr-3 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .mr-xl-3 {
        margin: 1.5rem
    }

    .mr-xl-t-3 {
        margin-top: 1.5rem
    }

    .mr-xl-b-3 {
        margin-bottom: 1.5rem
    }

    .mr-xl-l-3 {
        margin-left: 1.5rem
    }

    .mr-xl-r-3 {
        margin-right: 1.5rem
    }

    .mr-xl-tb-3 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem
    }

    .mr-xl-lr-3 {
        margin-left: 1.5rem;
        margin-right: 1.5rem
    }

    .pd-xl-4 {
        padding: 2rem
    }

    .pd-xl-t-4 {
        padding-top: 2rem
    }

    .pd-xl-b-4 {
        padding-bottom: 2rem
    }

    .pd-xl-l-4 {
        padding-left: 2rem
    }

    .pd-xl-r-4 {
        padding-right: 2rem
    }

    .pd-xl-tb-4 {
        padding-top: 2rem;
        padding-bottom: 2rem
    }

    .pd-xl-lr-4 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .mr-xl-4 {
        margin: 2rem
    }

    .mr-xl-t-4 {
        margin-top: 2rem
    }

    .mr-xl-b-4 {
        margin-bottom: 2rem
    }

    .mr-xl-l-4 {
        margin-left: 2rem
    }

    .mr-xl-r-4 {
        margin-right: 2rem
    }

    .mr-xl-tb-4 {
        margin-top: 2rem;
        margin-bottom: 2rem
    }

    .mr-xl-lr-4 {
        margin-left: 2rem;
        margin-right: 2rem
    }

    .pd-xl-5 {
        padding: 2.5rem
    }

    .pd-xl-t-5 {
        padding-top: 2.5rem
    }

    .pd-xl-b-5 {
        padding-bottom: 2.5rem
    }

    .pd-xl-l-5 {
        padding-left: 2.5rem
    }

    .pd-xl-r-5 {
        padding-right: 2.5rem
    }

    .pd-xl-tb-5 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .pd-xl-lr-5 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .mr-xl-5 {
        margin: 2.5rem
    }

    .mr-xl-t-5 {
        margin-top: 2.5rem
    }

    .mr-xl-b-5 {
        margin-bottom: 2.5rem
    }

    .mr-xl-l-5 {
        margin-left: 2.5rem
    }

    .mr-xl-r-5 {
        margin-right: 2.5rem
    }

    .mr-xl-tb-5 {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem
    }

    .mr-xl-lr-5 {
        margin-left: 2.5rem;
        margin-right: 2.5rem
    }

    .pd-xl-6 {
        padding: 3rem
    }

    .pd-xl-t-6 {
        padding-top: 3rem
    }

    .pd-xl-b-6 {
        padding-bottom: 3rem
    }

    .pd-xl-l-6 {
        padding-left: 3rem
    }

    .pd-xl-r-6 {
        padding-right: 3rem
    }

    .pd-xl-tb-6 {
        padding-top: 3rem;
        padding-bottom: 3rem
    }

    .pd-xl-lr-6 {
        padding-left: 3rem;
        padding-right: 3rem
    }

    .mr-xl-6 {
        margin: 3rem
    }

    .mr-xl-t-6 {
        margin-top: 3rem
    }

    .mr-xl-b-6 {
        margin-bottom: 3rem
    }

    .mr-xl-l-6 {
        margin-left: 3rem
    }

    .mr-xl-r-6 {
        margin-right: 3rem
    }

    .mr-xl-tb-6 {
        margin-top: 3rem;
        margin-bottom: 3rem
    }

    .mr-xl-lr-6 {
        margin-left: 3rem;
        margin-right: 3rem
    }

    .pd-xl-7 {
        padding: 3.5rem
    }

    .pd-xl-t-7 {
        padding-top: 3.5rem
    }

    .pd-xl-b-7 {
        padding-bottom: 3.5rem
    }

    .pd-xl-l-7 {
        padding-left: 3.5rem
    }

    .pd-xl-r-7 {
        padding-right: 3.5rem
    }

    .pd-xl-tb-7 {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem
    }

    .pd-xl-lr-7 {
        padding-left: 3.5rem;
        padding-right: 3.5rem
    }

    .mr-xl-7 {
        margin: 3.5rem
    }

    .mr-xl-t-7 {
        margin-top: 3.5rem
    }

    .mr-xl-b-7 {
        margin-bottom: 3.5rem
    }

    .mr-xl-l-7 {
        margin-left: 3.5rem
    }

    .mr-xl-r-7 {
        margin-right: 3.5rem
    }

    .mr-xl-tb-7 {
        margin-top: 3.5rem;
        margin-bottom: 3.5rem
    }

    .mr-xl-lr-7 {
        margin-left: 3.5rem;
        margin-right: 3.5rem
    }

    .pd-xl-8 {
        padding: 4rem
    }

    .pd-xl-t-8 {
        padding-top: 4rem
    }

    .pd-xl-b-8 {
        padding-bottom: 4rem
    }

    .pd-xl-l-8 {
        padding-left: 4rem
    }

    .pd-xl-r-8 {
        padding-right: 4rem
    }

    .pd-xl-tb-8 {
        padding-top: 4rem;
        padding-bottom: 4rem
    }

    .pd-xl-lr-8 {
        padding-left: 4rem;
        padding-right: 4rem
    }

    .mr-xl-8 {
        margin: 4rem
    }

    .mr-xl-t-8 {
        margin-top: 4rem
    }

    .mr-xl-b-8 {
        margin-bottom: 4rem
    }

    .mr-xl-l-8 {
        margin-left: 4rem
    }

    .mr-xl-r-8 {
        margin-right: 4rem
    }

    .mr-xl-tb-8 {
        margin-top: 4rem;
        margin-bottom: 4rem
    }

    .mr-xl-lr-8 {
        margin-left: 4rem;
        margin-right: 4rem
    }

    .pd-xl-9 {
        padding: 4.5rem
    }

    .pd-xl-t-9 {
        padding-top: 4.5rem
    }

    .pd-xl-b-9 {
        padding-bottom: 4.5rem
    }

    .pd-xl-l-9 {
        padding-left: 4.5rem
    }

    .pd-xl-r-9 {
        padding-right: 4.5rem
    }

    .pd-xl-tb-9 {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem
    }

    .pd-xl-lr-9 {
        padding-left: 4.5rem;
        padding-right: 4.5rem
    }

    .mr-xl-9 {
        margin: 4.5rem
    }

    .mr-xl-t-9 {
        margin-top: 4.5rem
    }

    .mr-xl-b-9 {
        margin-bottom: 4.5rem
    }

    .mr-xl-l-9 {
        margin-left: 4.5rem
    }

    .mr-xl-r-9 {
        margin-right: 4.5rem
    }

    .mr-xl-tb-9 {
        margin-top: 4.5rem;
        margin-bottom: 4.5rem
    }

    .mr-xl-lr-9 {
        margin-left: 4.5rem;
        margin-right: 4.5rem
    }

    .pd-xl-10 {
        padding: 5rem
    }

    .pd-xl-t-10 {
        padding-top: 5rem
    }

    .pd-xl-b-10 {
        padding-bottom: 5rem
    }

    .pd-xl-l-10 {
        padding-left: 5rem
    }

    .pd-xl-r-10 {
        padding-right: 5rem
    }

    .pd-xl-tb-10 {
        padding-top: 5rem;
        padding-bottom: 5rem
    }

    .pd-xl-lr-10 {
        padding-left: 5rem;
        padding-right: 5rem
    }

    .mr-xl-10 {
        margin: 5rem
    }

    .mr-xl-t-10 {
        margin-top: 5rem
    }

    .mr-xl-b-10 {
        margin-bottom: 5rem
    }

    .mr-xl-l-10 {
        margin-left: 5rem
    }

    .mr-xl-r-10 {
        margin-right: 5rem
    }

    .mr-xl-tb-10 {
        margin-top: 5rem;
        margin-bottom: 5rem
    }

    .mr-xl-lr-10 {
        margin-left: 5rem;
        margin-right: 5rem
    }
}

body {
    font-family: Montserrat, sans-serif;
    overflow-x: hidden
}

body.is--not-scrolable {
    overflow: hidden
}

.section-padding {
    padding: 5rem 0
}

header {
    position: fixed;
    top: 15px;
    left: 15px;
    padding: 10px 20px;
    border-radius: 50px;
    z-index: 99999999;
    min-height: 0;
    transition: all .5s;
    background-color: #fff;
    box-shadow: 1px 1px 6px hsla(0, 0%, 59.6%, .4)
}

header .navigation {
    display: flex;
    align-items: center;
    flex-direction: column
}

header .navigation--head {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    cursor: pointer
}

/* header .navigation--bar {
  margin-right: 15px
} */

header .navigation--bar span {
    background-color: #36cca5;
    width: 24px;
    height: 3px;
    border-radius: 5px;
    display: block;
    margin-bottom: 3px
}

header .navigation--bar span:last-child {
    margin-bottom: 0
}

header .navigation--logo {
    max-width: 205px
}

header .navigation--logo svg path {
    fill: #36cca5
}

header.is--open {
    border-radius: 10px
}

header.is--open,
header.is--sticked {
    background-color: #fff;
    box-shadow: 1px 1px 6px hsla(0, 0%, 59.6%, .4)
}

header.is--sticked .menu-container {
    max-width: 25px
}

header.is--sticked .menu-container svg path,
header.is--sticked .name-container path {
    fill: #686868
}

.navigation--items {
    flex: 0 0 100%;
    display: none;
    padding-top: 40px;
    align-self: flex-start
}

.navigation--items ul {
    list-style: none;
    padding: 0
}

.navigation--items ul li {
    margin-bottom: 20px
}

.navigation--items ul li a {
    color: #757575;
    font-size: 21px
}

/* [data-first-layout=true] {
    margin-top: 3rem
} */

.description {
    font-size: 16px;
    /* text-align: justify; */
}

.aligntext {
    text-align: justify;
}

@media screen and (min-width:768px) {
    .description {
        font-size: calc(16px + (400vw - 3072px)/598)
    }
}

@media screen and (min-width:1366px) {
    .description {
        font-size: 20px
    }
}


.our-clients .client--intro {
    text-align: center;
    flex: 0 0 20%
}

.our-clients .client--intro:nth-of-type(2n) {
    margin-top: 5rem
}

.our-clients .client--image {
    background-color: #fdfdfd;
    height: 120px;
    width: 120px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 1px 0 2px 5px rgba(88, 181, 252, .33);
    transition: all .5s;
    margin: 0 auto 25px;
    overflow: hidden
}

.our-clients .client--image:hover {
    box-shadow: 1px 0 2px 5px #58B5FC;
}

.our-clients .client--image:hover img {
    filter: grayscale(0);
    opacity: 1
}

.our-clients .client--image img {
    filter: grayscale(100%);
    opacity: .7;
    transition: all .5s;
    max-height: 100%
}

.our-clients .client--name {
    font-size: 15px;
    font-family: Comfortaa, cursive
}

.our-clients .clients--more {
    font-weight: 800;
    font-size: 25px;
    margin-bottom: 0
}

.stats-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.stats-list .individual-stat {
    flex: 0 0 20%;
    text-align: center
}

.stats-list .stat-figures {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center
}

.stats-list .stat-figures p {
    position: absolute;
    top: 50%;
    margin: 0;
    font-size: 20px;
    transform: translateY(-50%);
    transition: all .5s;
    font-weight: 700
}

.stats-list .stat-figures .stat-bg {
    transition: all .5s
}

.stats-list .description {
    font-size: 15px;
    font-family: Comfortaa
}

.individual-stat:hover .stat-figures p {
    text-shadow: 1px 1px 2px hsla(0, 0%, 40%, .6)
}

.individual-stat:nth-of-type(odd):hover .stat-figures .stat-bg {
    animation: anticlockwise-rotation 3s linear infinite
}

.individual-stat:nth-of-type(2n):hover .stat-figures .stat-bg {
    animation: clockwise-rotation 3s linear infinite
}

@keyframes clockwise-rotation {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(-1000deg)
    }
}

@keyframes anticlockwise-rotation {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1000deg)
    }
}


@media screen and (min-width:320px) and (max-width:575px) {

    .stats-list .individual-stat,
    featured-tools .featured-tools-list .individual-tool {
        flex: 1 0 50%;
        padding: 0 10px
    }

    .stats-list .individual-stat:nth-of-type(2n) {
        margin-top: 0
    }
}

@media screen and (min-width:576px) and (max-width:767px) {
    featured-tools .featured-tools-list .individual-tool {
        flex: 1 0 33.33333%;
        padding: 0 10px
    }

    .stats-list .individual-stat {
        flex: 1 0 33.3333%;
        padding: 0 10px
    }

    .stats-list .individual-stat:nth-of-type(2n) {
        margin-top: 0
    }
}

@media screen and (max-width:767px) {
    .caption--logo {
        top: 35vh;
        left: calc(50% - 115px);
        max-width: 55px
    }

    /* [data-first-layout=true] {
        margin-top: 2rem
    } */

    .hero--caption-container {
        left: calc(50% + 55px);
        top: 35vh
    }

    .hero--caption-container .hero-heading svg {
        width: 210px;
        height: auto
    }

    .hero-banner {
        height: 90vh;
        min-height: 360px
    }

    .our-clients {
        margin: 0 -15px
    }

    .our-clients .slick-list {
        padding-top: 15px !important;
        padding-bottom: 15px !important
    }

    .our-clients .js--more-clients {
        display: none
    }

    .our-clients .client--intro {
        flex: 0 0 auto;
        padding: 0 10px
    }

    .our-clients .client--intro:nth-of-type(2n) {
        margin-top: 0
    }

    .our-clients .client--image {
        height: 80px;
        width: 80px;
        padding: 10px
    }

    testimonials>.testimonial--bg {
        height: 170%;
        top: -8rem
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    .caption--logo {
        top: 35vh;
        left: calc(50% - 115px);
        max-width: 55px
    }

    .hero--caption-container {
        left: calc(50% + 55px);
        top: 35vh
    }

    .hero--caption-container .hero-heading svg {
        width: 210px;
        height: auto
    }

    .hero-banner {
        /* height: 70vh; */
        min-height: 360px
    }

    .stats-list .individual-stat {
        flex: 1 0 33.3333%;
        padding: 0 10px
    }

    .stats-list .individual-stat:nth-of-type(2n) {
        margin-top: 0
    }
}

@media screen and (min-width:992px) and (max-width:1199px) {
    .stats-list .individual-stat {
        flex: 1 0 33.3333%;
        padding: 0 10px
    }

    .stats-list .individual-stat:nth-of-type(2n) {
        margin-top: 0
    }
}

.grtyoutube-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999
}

.grtyoutube-popup-content {
    margin-top: 150px;
    width: 95%;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    position: relative
}

.grtyoutube-popup-close {
    position: absolute;
    top: -30px;
    right: 0;
    color: #fff;
    font-size: 25px;
    width: 17px;
    height: 17px;
    cursor: pointer
}

.grtyoutube-iframe {
    width: 100%;
    height: 480px
}

@media (max-width:767px) {
    .grtyoutube-iframe {
        width: 100%;
        height: 350px
    }
}

.grtyoutube-dark-theme {
    background: rgba(0, 0, 0, .85)
}

.grtyoutube-dark-theme .grtyoutube-popup-content {
    -webkit-box-shadow: 0 0 8px 0 hsla(0, 0%, 100%, .4);
    -moz-box-shadow: 0 0 8px 0 hsla(0, 0%, 100%, .4);
    box-shadow: 0 0 8px 0 hsla(0, 0%, 100%, .4)
}

/* .grtyoutube-dark-theme .grtyoutube-popup-close {
    background: url(icon-close-white.c3d5f977.png) no-repeat
} */

.grtyoutube-light-theme {
    background: hsla(0, 0%, 100%, .85)
}

.grtyoutube-light-theme .grtyoutube-popup-content {
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, .4);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .4)
}

/* .grtyoutube-light-theme .grtyoutube-popup-close {
    background: url(icon-close-black.cb81ab58.png) no-repeat
} */

.fa {

    font-size: 30px;
    width: 30px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 50%;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {

    color: white;
    padding: 5px;
    padding-right: 0px;
    padding-left: 0px;
}

.fa-twitter {
    padding: 5px;
    color: white;
    padding-right: 0px;
    padding-left: 0px;
}

.fa-instagram {
    color: white;
    padding: 5px;
    padding-right: 0px;
    padding-left: 0px;
}

.textalign {
    text-align: justify;
}



.bottomflexcontainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    z-index: 100;
    color: white;
    list-style-type: none;
    font-size: 20px;
    justify-content: space-around;
    padding: 0;
    font-weight: bold;
    /* right: 10px; */
}

@media(max-width:900px) {
    .bottomflexcontainer {
        font-size: 8px;
    }

    /* .btnsize {
        min-width: 50px;
    } */

    .btn-size {
        font-size: 8px;
    }
}

/* .flexcontainer li {
  margin: 10px
} */

.mobdisplay {
    display: none;
}

@media(max-width:900px) {
    .mobdisplay {
        display: block;
    }

    .compdisplay {
        display: none;
    }
}

.textrotate {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    font-weight: 500;
}

.circle {
    width: 2em;
    height: 2em;
    text-align: center;
    line-height: 2em;
    border-radius: 1em;
    background: #36cca5;
    margin: 0 1em;
    display: inline-block;
    color: white;
    position: relative;
    transform: rotate(90deg);
}

.circle::before {
    content: '';
    position: absolute;
    top: .9em;
    left: -4em;
    width: 4em;
    height: .2em;
    background: #36cca5;
    z-index: -1;
}

.squarebox {
    border: 1px solid #94D141;
    padding: 10px;
    /* padding-top: 30px; */
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .squarebox:hover {
    padding-top: 20px;
} */

.grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
}

.gridcontainer {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
}

.griditem1 {
    justify-self: self-end;
}

.griditem2 {
    grid-column: 2/4;
    justify-self: center;
    align-self: center;
}

.griditem3 {
    grid-column: 1/4;
    display: none;
}

.squarebox:hover .griditem3 {
    display: block;
}

.griditem4 {
    grid-column: 1/4;
    justify-self: self-end;
    display: none;
}



.squarebox:hover .griditem4 {
    display: block;
}

.needtextsize {
    /* font-size: 9px; */
    font-size: 12px;
    color: black;
}



.needtext {
    text-align: center;
    color: black;
    font-size: 15px;
    margin-left: 30px;
}

.squarebox:hover .needtext {
    font-size: 15px;
    margin-left: 0px;

}

.widthneedicon {
    width: 60px;
    /* width: 40px; */
}

.squarebox:hover .widthneedicon {
    width: 40px;
}

.readmoretext {
    font-size: 10px;
    color: #94D141;

}

.readmoretext:hover {
    text-decoration: underline;
    color: #94D141;


}

.solutiontext {
    font-size: 40px;
}

.desctext {
    font-size: 25px;
    font-weight: 500;
}

/* .chooseflexcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.icon {
  flex: 31.33%;
  max-width: 31.33%;
  margin-left: 1%;
  margin-right: 1%;
} */
.individual-box {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 20px; */
}



.widthchooseimg {
    width: 150px;
}

@media(max-width:600px) {
    .widthchooseimg {
        width: 100px;
    }
}

.marginbottomchoose {
    margin-bottom: 100px;
}

@media(max-width:600px) {
    .marginbottomchoose {
        margin-bottom: 25px;
    }
}

.individual-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fdfdfd;
    height: 180px;
    width: 180px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 1px 0 2px 5px rgba(248, 97, 86, 0.33);
    transition: all .5s;
    margin: 0 auto 25px;
    overflow: hidden
}

@media(max-width:1000px) {
    .individual-circle {
        height: 150px;
        width: 150px;
    }
}

.individual-circle:hover {
    -webkit-box-shadow: 1px 0 2px 5px #F86156;
    box-shadow: 1px 0 2px 5px #F86156;
}



.descsector {
    font-size: 12px;
    color: black;
    text-align: center;
    display: none;
    margin-bottom: 3px;
}

@media(max-width:600px) {
    .descsector {
        font-size: 10px;
    }
}

.individual-circle:hover .descsector {
    display: block;
}

.sectorebtnsize {
    display: none;
    font-size: 10px;
    color: #F86156;
}

.sectorebtnsize:hover {

    color: #F86156;
    text-decoration: underline;
}

.individual-circle:hover .sectorebtnsize {
    display: block;
}

.titlesector {
    color: black;
    font-weight: bold;
    text-align: center;
    margin-bottom: 3px;
    font-size: 12px;
    text-align: center;
}

.individual-circle:hover .titlesector {
    /* font-size: 12px;
    text-align: center; */
    display: none;
}

@media(max-width:600px) {
    .individual-circle:hover .titlesector {
        /* font-size: 10px;
        text-align: center; */
        display: none;
    }
}

.widthsectorimg {
    width: 100px;
    /* display: none; */
}

.individual-circle:hover .widthsectorimg {
    width: 50px;
    margin: 0 auto;
    display: none;
}

.margbotrowsector {
    margin-bottom: 50px;
}

@media(max-width:600px) {
    .margbotrowsector {
        margin-bottom: 0px;
    }

    .marginbotneed {
        margin-bottom: 25px;
    }
}

.margbomtextsector {
    margin-bottom: 30px;
}

@media(max-width:600px) {
    .margbomtextsector {
        margin-bottom: 15px;
    }
}

.marbotboxneed {
    margin-bottom: 5px;
}

@media(max-width:600px) {
    .marbotboxneed {
        margin-bottom: 0px;
    }
}

.demobtn {
    background-color: white;
    color: #36cca5;

}

.demobtn:hover {
    color: #36cca5 !important;
}

.colortext {
    color: black;

}

.widthmelzologo {
    width: 100px;
}

@media(max-width:600px) {
    .widthmelzologo {
        width: 50px
    }
}

.transparentbtn {
    background: transparent;
    border: 0;
    outline: none;
}

.widthmelzobtn {
    width: 100px;
}

.bottomimage {
    position: absolute;
    width: 100%;
}

.textwidth {
    width: 600px;
}

@media(max-width:600px) {
    .textwidth {
        width: 200px;
    }
}





.colonav {
    color: black;
}

.melzologo {
    width: 40px
}

/* .borderbottom {
    border-bottom: 3px solid #62b4f9;
} */

.underlinebottom {
    text-decoration: underline;
    color: #62b4f9;
    font-size: 15px;
}

.underlinebottom:hover {
    text-decoration: underline !important;
    color: #62b4f9 !important;
}

.widthicon {
    width: 80px;
}

.endalign {
    text-align: end;
}

@media(max-width:600px) {
    .endalign {
        text-align: center;
    }
}

.marginrow {
    margin-bottom: 50px;
}

@media(max-width:600px) {
    .marginrow {
        margin-bottom: 0px;
    }
}

.heightdescription {
    height: 150px;
    text-align: justify;
}




/* .producttitletext {
    height: 500px;

}

.producttitletext1 {
    height: 500px;
    position: absolute;
    top: -60px;
} */

.producttitletext2 {
    height: 400px;
    position: absolute;
    left: 10px;
    margin-top: 70px;
}

@media(max-width:600px) {
    .producttitletext2 {
        height: 200px;
        left: 1px;

    }


}



@media(max-width:600px) {
    .marginbottomproducts {
        margin-top: 100px;
    }
}

.demoposition {
    background: #36cca5;
}

.demoBtn {
    background: #36cca5;
}

#whatsappBtn {
    background-color: #36cca5;
}

#myBtn {

    background-color: #36cca5;
    color: white;


}

/* @media(max-width:600px) {
    #myBtn {
        right: 1px;
    }


} */

#myBtn:hover {
    background-color: White;
    color: #36cca5;
    border: 1px solid #36cca5 !important;
}



.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

@-webkit-keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

.mySlides {
    /* display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; */
    display: none;

}

.center-button {
    display: flex;
    justify-content: center;
}

.marbottomtext {
    margin-bottom: 20px;
}

.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    /* background-color: rgba(78, 225, 190, .33); */
    border-radius: 50%;
    background-color: #36cca5;
    opacity: 0.33;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dotFirst {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    /* background-color: rgba(78, 225, 190, .33); */
    border-radius: 50%;
    background-color: #36cca5;
    opacity: 0.33;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dotFirstR {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    /* background-color: rgba(78, 225, 190, .33); */
    border-radius: 50%;
    background-color: #36cca5;
    opacity: 0.33;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dotNew {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    /* background-color: rgba(78, 225, 190, .33); */
    border-radius: 50%;
    background-color: #36cca5;
    opacity: 0.33;
    display: inline-block;
    transition: background-color 0.6s ease;
}




.dotred {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    /* background-color: rgba(248, 97, 86, 0.33); */
    border-radius: 50%;
    background-color: #F86156;
    opacity: 0.33;
    display: inline-block;
    transition: background-color 0.6s ease;
}

/* 
.dotredactive,
.dotred:hover {} */

.dotgreen {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    /* background-color: rgba(148, 209, 65, 0.33); */
    border-radius: 50%;
    background-color: #94D141;
    opacity: 0.33;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dotpurple {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    /* background-color: rgba(148, 209, 65, 0.33); */
    border-radius: 50%;
    background-color: #b604b6;
    opacity: 0.33;
    display: inline-block;
    transition: background-color 0.6s ease;
}

@media(max-width:600px) {
    .dotpurple {
        height: 13px;
        width: 13px;
        margin: 0 0px;

    }
}

/* .dotgreenactive,
.dotgreen:hover {
    background-color: #94D141;
} */
.yellowlineafter {
    font-size: 40px;
    position: relative;

}

.yellowlineafter:after {
    content: "";
    display: inline-block;
    /* height: 0.5em; */
    vertical-align: top;
    width: 150%;
    margin-right: -100%;
    margin-top: 16px;
    transform: translateX(-8px);
    position: absolute;
    border-top: 5px solid #FFCA65;
}

@media(max-width:600px) {
    .yellowlineafter {
        font-size: 20px;
        text-align: center;

    }

    .yellowlineafter:after {
        margin-top: 8px;
        width: 100%;
        border-top: 3px solid #FFCA65;

    }

    .size {
        font-size: 15px;
    }
}



.yellowtitlebefore {
    font-size: 40px;
    position: relative;

}

.yellowtitlebefore:after {
    content: "";
    display: inline-block;
    /* height: 0.5em; */
    vertical-align: top;
    width: 150%;
    margin-right: -100%;
    margin-top: 16px;
    transform: translateX(-10px);
    position: absolute;
    border-top: 5px solid #FFCA65;
}

@media(max-width:600px) {


    .yellowtitlebefore {
        font-size: 20px;
        text-align: center;

    }

    .yellowtitlebefore:after {
        margin-top: 7px;
        width: 100%;
        border-top: 3px solid #FFCA65;

    }
}

.centertext {
    text-align: center;
}

.brandtitlebefore {
    font-size: 40px;
    position: relative;

}

.brandtitlebefore:before {
    content: "";
    display: inline-block;
    /* height: 0.5em; */
    vertical-align: top;
    width: 150%;
    margin-right: -150%;
    margin-top: 16px;
    transform: translateX(-99%);
    border-top: 5px solid #36cca5;

}

@media(max-width:600px) {
    .brandtitlebefore {
        font-size: 20px;
        text-align: center;

    }

    .brandtitlebefore:before {
        margin-top: 7px;
        transform: translateX(-98%);
        width: 100%;
        margin-right: -100%;
        border-top: 3px solid #36cca5;

    }
}

.redlineafter {
    font-size: 40px;
    position: relative;

}

.redlineafter:after {
    content: "";
    display: inline-block;
    /* height: 0.5em; */
    vertical-align: top;
    width: 150%;
    margin-right: -100%;
    margin-top: 16px;
    transform: translateX(-10px);
    position: absolute;
    border-top: 5px solid #F86156;

}

@media(max-width:600px) {
    .redlineafter {
        font-size: 20px;
        text-align: center;

    }

    .redlineafter:after {
        margin-top: 7px;
        width: 100%;
        border-top: 3px solid #F86156;

    }

    .size {
        font-size: 15px;
    }
}

.bluelineafter {
    font-size: 40px;
    position: relative;

}

.bluelineafter:before {
    content: "";
    display: inline-block;
    /* height: 0.5em; */
    vertical-align: top;
    width: 150%;
    margin-right: -150%;
    margin-top: 16px;
    transform: translateX(-98%);
    border-top: 5px solid #62b4f9;

}


.purplelineafter {
    font-size: 40px;
    position: relative;

}

.purplelineafter:before {
    content: "";
    display: inline-block;
    /* height: 0.5em; */
    vertical-align: top;
    width: 150%;
    margin-right: -150%;
    margin-top: 16px;
    transform: translateX(-98%);
    border-top: 5px solid #b604b6;

}



.demoafter {
    font-size: 40px;
    position: relative;

}

.demoafter:after {
    content: "";
    display: inline-block;
    /* height: 0.5em; */
    vertical-align: top;
    width: 150%;
    margin-right: -100%;
    margin-top: 16px;
    transform: translateX(-10px);
    position: absolute;
    border-top: 5px solid #36cca5;
}

@media(max-width:600px) {

    .purplelineafter {
        font-size: 20px;
        text-align: center;

    }

    .purplelineafter:before {
        margin-top: 7px;
        transform: translateX(-96%);
        width: 100%;
        margin-right: -100%;
        border-top: 3px solid #b604b6;

    }

    .demoafter {
        font-size: 20px;
        text-align: center;

    }

    .demoafter:after {
        margin-top: 7px;
        width: 100%;
        border-top: 3px solid #36cca5;

    }


    .bluelineafter {
        font-size: 20px;
        text-align: center;

    }

    .bluelineafter:before {
        margin-top: 7px;
        transform: translateX(-97%);
        width: 100%;
        margin-right: -100%;
        border-top: 3px solid #62b4f9;

    }

    .size {
        font-size: 15px;
    }
}



.needtitlebefore {
    font-size: 40px;
    position: relative;

}

.needtitlebefore:before {
    content: "";
    display: inline-block;
    /* height: 0.5em; */
    vertical-align: top;
    width: 150%;
    margin-right: -150%;
    margin-top: 16px;
    transform: translateX(-98%);
    border-top: 5px solid #94D141;

}

@media(max-width:600px) {
    .needtitlebefore {
        font-size: 20px;
        text-align: center;

    }

    .needtitlebefore:before {
        margin-top: 7px;
        transform: translateX(-96%);
        width: 100%;
        margin-right: -100%;
        border-top: 3px solid #94D141;

    }
}

.doSeveny {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    /* background-color: rgba(78, 225, 190, .33); */
    border-radius: 50%;
    background-color: #36cca5;
    opacity: 0.33;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.our--clients>*:nth-of-type(4) img {

    box-shadow: 0px 0 2px 3px #b604b6;
}

@media(max-width:600px) {
    .our--clients>*:nth-of-type(2) img {

        box-shadow: 0px 0 2px 3px #b604b6;
    }
}

.popup__close {

    color: #7D7EE8;
    border: 2px solid #7D7EE8;

}

.popup__close :hover {
    color: #7D7EE8 !important;
}

.blueinputclass {
    color: #7D7EE8 !important;

    border-bottom: 3px solid #7D7EE8 !important;
}


input.blueinputclass ::-moz-placeholder {
    color: #7D7EE8 !important;

}

input.blueinputclass :-ms-input-placeholder {
    color: #7D7EE8 !important;

}

input.blueinputclass::-webkit-input-placeholder {
    color: #7D7EE8 !important;

}

input.blueinputclass::placeholder {
    color: #7D7EE8 !important;


}

.select-selectedform:after {

    border-color: #7D7EE8 transparent transparent transparent;
}

.select-selectedform.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
}

.select-itemsform div,
.select-selectedform {
    color: #7D7EE8;

    border-bottom: 3px solid #7D7EE8 !important;

}


body::-webkit-scrollbar-thumb {
    background-color: #36cca5;

}